import React from "react";

const InstagramButton = ({ username }) => {
  const handleButtonClick = () => {
    window.open(`https://www.instagram.com/${username}/`, "_blank");
  };

  return (
    <button
      style={{
        fontSize: "0.8em",
        padding: "1.2em 2.5em",
        backgroundColor: "#2865C0",
        color: "#fff",
        border: "none",
        borderRadius: "0.3em",
        cursor: "pointer",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        maxHeight: "4em",
        maxWidth: "16em",
        margin: "1rem 0 ",
      }}
      onClick={handleButtonClick}
    >
      Instagram
    </button>
  );
};

export default InstagramButton;
