import React from "react"
import "../../css/typography.css"
import styled from "styled-components"
import { Link } from "gatsby"

const InfoDiv = styled.div`
    background-color: #2865C0;
    padding: 1% 2% 1% 2%;
    display: flex;
    flex-direction: column;
    font-family: "H Roman";
    color: white;
    font-size: 17px;
    margin-bottom: 8px;
    width: 40%;
    z-index: 1;

    h4 {
        font-family: 'S Bold';
        font-size: 25px;
        margin: 0 0 10px 0;
    }

    span {
        font-family: 'H Bold';
    }

    p {
        margin: 0 0 10px 0;
    }

    a {
        text-decoration: none;
        color: #2865C0;
        font-family: 'S Bold';
        font-size: 15px;
        background-color: white;
        border: 0px;
        border-radius: 5px;
        width: 26%;
        padding: 1.4%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    @media only screen and (min-width: 1800px) {
        font-size: 25px;
        h4 {
            font-size: 30px;
        }
        a {
            font-size: 25px;
            width: 50%;
        }
    }
    
    @media only screen and (min-width: 3500px) {
        a {
            width: 25%;
        }
    }
    @media only screen and (min-width: 4000px) {
        font-size: 33px;
        h4 {
            font-size: 40px;
        }
        a {
            font-size: 33px;
        }
    }
    @media only screen and (max-width: 752px) {
        width: 60%; 
        padding: 2% 3% 5% 3%;
        font-size: 15px;

        p {
            line-height: 20px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 910px) {
        a {
            width: 60%;
        }
    }

`


const BigInfoBox = (props) => {

    return (
        <InfoDiv>
            <h4>Información del curso</h4>
            <p><span>Duración: </span>{props.duracion}</p>
            <p><span>Cupo: </span>{props.cupo}</p>
            <p><span>Costo: </span>aportación voluntaria</p>
            <p><span>Precio sugerido: </span>{props.precio}</p>
            <Link to='/contacto'>ME INTERESA</Link>
        </InfoDiv>
    )
}

export default BigInfoBox
