import React from "react";
import "../../css/typography.css";
import useWindowSize from "../../hooks/useWindowSize";
import InfoBox from "./infoBox";
import styled from "styled-components";
import CurvedDown from "../../images/curved/CurvedDown.webp";
import CurvedUp from "../../images/curved/CurvedUp.webp";
import Class from "../../images/icons/Class.webp";
import Time from "../../images/icons/Time.webp";
import Students from "../../images/icons/Students.webp";
import BigInfoBox from "./bigInfoBox";
import InstagramButton from "../buttons/InstagramButton";

const FirstDiv = styled.div`
  background-image: url(${CurvedDown});
  background-size: cover;
  position: relative;
  padding: 8% 3% 15% 3%;
  z-index: -2;
  background-position-y: -65px;
  background-repeat: no-repeat;
  background-color: #efe8dc;

  .first-img {
    position: absolute;
    right: 0;
    top: 10%;
    width: 65%;
    z-index: -1;
  }

  .second-img {
    position: absolute;
    right: ${(props) => props.right};
    top: ${(props) => props.top};
    width: ${(props) => props.imgWidth};
  }

  .info-div {
    display: flex;
    justify-content: space-between;
    width: 35%;
    margin-top: 40px;
  }

  h1 {
    font-family: "S Bold";
    color: #484848;
    font-size: 30px;
    margin: 0;
  }

  .phrase {
    font-family: "S Italic";
    color: #484848;
    font-size: 25px;
    margin: 0;
  }

  @media only screen and (min-width: 1800px) {
    h1 {
      font-size: 40px;
    }
    .phrase {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 2500px) {
    h1 {
      font-size: 45px;
    }
    .phrase {
      font-size: 35px;
    }
  }
  @media only screen and (min-width: 3500px) {
    h1 {
      font-size: 48px;
    }
    .phrase {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 1120px) {
    h1 {
      width: 75%;
    }
  }
  @media only screen and (max-width: 1172px) {
    .info-div {
      width: 48%;
    }
  }
  @media only screen and (max-width: 995px) {
    .phrase {
      width: 60%;
    }
  }
  @media only screen and (max-width: 752px) {
    h1 {
      font-size: 25px;
    }
    .phrase {
      font-size: 20px;
      width: 70%;
    }
    .info-div {
      flex-wrap: wrap;
      width: 35%;
    }
    .first-img {
      top: 15%;
      width: 75%;
    }
  }
  @media only screen and (max-width: 730px) {
    padding: 10% 3% 20% 3%;
  }
  @media only screen and (max-width: 610px) {
    .second-img {
      top: ${(props) => props.topMob};
      right: ${(props) => props.rightMob};
      width: ${(props) => props.imgWidthMob};
    }
  }
  @media only screen and (max-width: 551px) {
    padding: 10% 3% 25% 3%;

    .info-div {
      width: 80%;
    }
  }
  @media only screen and (max-width: 440px) {
    padding: 10% 3% 40% 3%;

    .info-div {
      width: 60%;
    }
  }
`;

//mobbbb top: 45%; right: 3%;

const SecondDiv = styled.div`
  background-color: #efe8dc;
  display: flex;
  padding: 4% 10% 4% 10%;
  font-family: "Regular";
  color: #3c3c3c;
  font-size: 16px;
  justify-content: space-between;
  font-family: "Regular";
  font-size: 20px;

  div {
    width: 50%;
    padding: 1%;
  }

  h3 {
    color: #2865c0;
    font-size: 25px;
    font-family: "S Bold";
  }

  ol {
    padding: 0 0 0 15px;
  }

  ol li {
    margin-bottom: 10px;
  }

  p {
    width: 90%;
  }

  @media only screen and (min-width: 1800px) {
    font-size: 25px;
    h3 {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 3500px) {
    font-size: 28px;
    h3 {
      font-size: 35px;
    }
  }
  @media only screen and (min-width: 4000px) {
    font-size: 33px;
    h3 {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 752px) {
    font-size: 17px;
    padding: 0 3% 4% 10%;

    flex-direction: column;
    div {
      margin-top: 0;
      width: 100%;
    }
    h3 {
      font-size: 20px;
    }
  }
`;

const ThirdDiv = styled.div`
  background-image: url(${CurvedUp});
  background-color: #efe8dc;
  background-size: cover;
  position: relative;
  padding: 10% 3% 5% 3%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`;

const Curso = (props) => {
  const windowSize = useWindowSize();

  return (
    <>
      <FirstDiv
        imgWidth={props.imgWidth}
        top={props.top}
        right={props.right}
        topMob={props.topMob}
        rightMob={props.rightMob}
        imgWidthMob={props.imgWidthMob}
      >
        <div>
          <h1>{props.title}</h1>
          <h2 className="phrase">{props.phrase}</h2>
          <div className="info-div">
            <InfoBox icon={Time} alt="icono-tiempo" text={props.time} />
            <InfoBox
              icon={Class}
              alt="icono-sesiones"
              sesiones={true}
              text={props.class}
            />
            <InfoBox
              icon={Students}
              alt="icono-estudiantes"
              text={props.students}
            />
          </div>
        </div>
        <img src={props.firstImg} alt={props.firstAlt} className="first-img" />
        <img
          src={props.secondImg}
          alt={props.secondAlt}
          className="second-img"
        />
      </FirstDiv>
      <SecondDiv>
        <div>
          <h3>Descripción</h3>
          <p className="description">{props.description}</p>

          {props.instagram && <InstagramButton username={props.instagram} />}
        </div>
        <div>
          <h3>Contenido</h3>
          <ol>
            {props.list.map((item, idx) => {
              return <li key={idx}>{item}</li>;
            })}
          </ol>
        </div>
      </SecondDiv>
      <ThirdDiv>
        <BigInfoBox
          duracion={props.duracion}
          precio={props.precio}
          cupo={props.cupo}
        />
      </ThirdDiv>
    </>
  );
};

export default Curso;
