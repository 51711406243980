import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Elefante from "../images/cursos/Elefante.webp";

const Existencialismo = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Existencialismo"
        phrase="Fundamentos e ideas principales."
        time="8 Horas"
        class="6 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Elefante}
        description="En este curso conoceremos las ideas principales del existencialismo a través de sus principales exponentes. El objetivo del curso es cuestionarnos nuestra propia manera de vivir, las motivaciones que nos llevan a decidir y adquirir un nuevo lenguaje que nos permita dotar nuestra propia existencia de profundidad, consciencia y libertad."
        list={[
          "Introducción a la filosofía existencialista.",
          "Libertad-responsabilidad-angustia y poder.",
          "Existencialismo creyente: Kierkegaard, Dostoievski y Buber. (2 sesiones).",
          "Existencialismo ateo: Nietzsche, De Beauvoir, Sartre (3 sesiones).",
        ]}
        duracion="6 sesiones de entre 80 y 90 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="20%"
        right="5%"
        topMob="15%"
        rightMob="5%"
        imgWidthMob="25%"
      />
    </Layout>
  ) : null;
};

export default Existencialismo;
