import React from "react"
import "../../css/typography.css"
import styled from "styled-components"

const InfoDiv = styled.div`
    background-color: #2865C0;
    padding: 1.5% 2% 1% 2%;
    display: flex;
    font-family: "H Roman";
    color: white;
    align-items: center;
    font-size: 16px;
    margin-bottom: 8px;

    img {
        width: 15px;
        margin-right: 10px;
    }
    .sesiones-icon {
        width: 10px;
    }

    @media only screen and (min-width: 1800px) {
        font-size: 25px;

        img {
            width: 20px;
        }
    }
    @media only screen and (min-width: 2500px) {
        font-size: 28px;
    }
    @media only screen and (min-width: 3500px) {
        
    }
    @media only screen and (min-width: 4000px) {
        
    }
`


const InfoBox = (props) => {
    console.log('aver', props.sesiones)
    return (
        <InfoDiv>
            <img src={props.icon} alt={props.alt} sesiones={props.sesiones} className={props.sesiones ? 'sesiones-icon': ''}/>
            <p>{props.text}</p>
        </InfoDiv>
    )
}

export default InfoBox
